import React, { Fragment, Component } from "react";
import HeaderGroup from "components/HeaderGroup";
import Section, { themes } from "components/Section";
import Header from "components/Header";
import ApplicationState from "context/ApplicationContext";
import styles from "./app.scss";
import ParallaxBannerImageText from "components/ParallaxBannerImageText";
import BannerTitle from "components/BannerTitle";
import Button, { ButtonLink, ButtonTypes } from "components/Button";
import MobileMenu from "components/MobileMenu";
import InlineSVG from "svg-inline-react";
import BannerTitleWordGroup, {
  COLOR_TYPE_GREEN,
  COLOR_TYPE_WHITE,
  COLOR_TYPE_BROWN,
  COLOR_TYPE_YELLOW
} from "components/BannerTitleWordGroup";
import SvgXLink from "components/SvgXLink";
import PriceCategory from "components/PriceCategory";
import PricesPage from "components/PricesPage";
import Countdown from "components/Countdown";

const calculateRatio = ratio => multiplier =>
  ratio * multiplier - multiplier / 2;

class App extends Component {
  state = {
    isMobileMenuActive: false,
    isPricesPageActive: false,
    iframeSrc: "about:blank"
  };

  /**
   * Get banner title animation type
   * @param {number} ratio
   * @returns {string}
   */
  static getBannerTitleAnimationType(ratio) {
    if (ratio <= 0.2) {
      return "animate-in";
    }
    if (ratio > 0.2 && ratio < 0.8) {
      return "animate-in-end";
    }
    if (ratio >= 0.8) {
      return "animate-out-end";
    }
  }

  /**
   * Update mobile menu active state
   * @param {boolean} state
   */
  setMobileMenuActive = state => {
    this.setState({ isMobileMenuActive: state });
  };

  /**
   * Update state price-list active
   * @param {boolean} state
   */
  setPricesPageActive = state => {
    this.setState({ isPricesPageActive: state });
  };

  componentDidMount() {
    this.setState({
      iframeSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.4005172927004!2d5.703936915892925!3d51.46916222131905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7237a2543b693%3A0xdbfb7b79493148e6!2sHof+Bruheze+16%2C+5704+NP+Helmond!5e0!3m2!1snl!2snl!4v1538687697712"
    });
  }

  render() {
    const { innerHeight } = typeof window !== "undefined" ? window : {};
    const height = innerHeight ? `${Math.round(innerHeight * 0.7)}px` : "70vh";
    const knipActiveHeight =
      innerHeight && innerHeight < 800 ? `100vh` : "70vh";

    return (
      <ApplicationState.Provider
        value={{
          isMobileMenuActive: this.state.isMobileMenuActive,
          isPricesPageActive: this.state.isPricesPageActive,
          setMobileMenuActive: this.setMobileMenuActive,
          setPricesPageActive: this.setPricesPageActive
        }}
      >
        <Fragment>
          <Header
            darkMode={this.state.isMobileMenuActive}
            onClick={item => {
              const element = document.querySelector(item.url);
              window.scroll({
                top: element.getBoundingClientRect().top - 30,
                left: 0,
                behavior: "smooth"
              });
              history.replaceState(undefined, item.title, item.url);
            }}
          />
          <MobileMenu
            active={this.state.isMobileMenuActive}
            onClick={() => this.setMobileMenuActive(false)}
          />
          <PricesPage active={this.state.isPricesPageActive} />
          <main className={styles.main}>
            <ParallaxBannerImageText
              backgroundPosition="top left"
              image="wallpaper"
              height={height}
              renderContent={($el, ratio) => (
                <BannerTitle
                  style={{ transform: `translateY(${ratio * 100 - 50}px)` }}
                >
                  <BannerTitleWordGroup
                    color={COLOR_TYPE_GREEN}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    Welkom bij
                  </BannerTitleWordGroup>
                  <BannerTitleWordGroup
                    color={COLOR_TYPE_WHITE}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    Annette Hair-Design
                  </BannerTitleWordGroup>
                </BannerTitle>
              )}
            />
            <Section theme={themes.GREEN_MODE}>
              <HeaderGroup
                id="annette-hairdesign"
                renderTitle={() => "Haal uit jouw haar wat erin zit"}
                svg={require("svg/scissors.svg")}
              />
              <div className={styles.columns2}>
                <p>
                  Annette Hair-Design biedt onbeperkte mogelijkheden door middel
                  van creatieve en vernieuwende technieken. Door te kijken naar
                  de persoonlijkheid, de inplant van het haar, de conditie en de
                  natuurlijke valling, creëren we een persoonlijk kapsel dat bij
                  je past.
                </p>
                <p>
                  Het is juist een uitdaging om iedere klant weer bewust te
                  maken van alle mogelijkheden die er zijn op het gebied van
                  haarmode. Het hele jaar door bezoeken we diverse cursussen en
                  shows waardoor je verzekerd bent van een kapsel volgens de
                  laatste trend!
                </p>

                <h3 style={{ marginBottom: 0 }}>Wij kiezen voor Goldwell</h3>
                <p>
                  In onze kapsalon gebruiken we producten van Goldwell. Deze
                  professionele haarproducten zijn verkrijgbaar in de salon.
                </p>
              </div>
            </Section>

            <ParallaxBannerImageText
              image="stichting-haarwensen"
              height={knipActiveHeight}
              backgroundPosition="right center"
              renderContent={($el, ratio) => (
                <BannerTitle
                  style={{ transform: `translateY(${ratio * 100 - 50}px)` }}
                >
                  <BannerTitleWordGroup
                    color={COLOR_TYPE_YELLOW}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    24 uur knip marathon
                  </BannerTitleWordGroup>
                  <BannerTitleWordGroup
                    color={COLOR_TYPE_WHITE}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    Voor Stichting Haarwensen
                  </BannerTitleWordGroup>

                  <BannerTitleWordGroup
                    color={COLOR_TYPE_YELLOW}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    Eindresultaat;{" "}
                    <span
                      style={{
                        fontSize: "1.3em",
                        color: "#e2dcc6",
                        whiteSpace: "nowrap"
                      }}
                    >
                      € 1576,35
                    </span>
                  </BannerTitleWordGroup>
                </BannerTitle>
              )}
            />

            <Section theme={themes.GREEN_MODE}>
              <HeaderGroup
                id="24-knippen"
                renderTitle={() => "24 uur knippen voor het goede doel"}
                svg={require("svg/scissors.svg")}
              />

              <h3>In de media</h3>

              <ul className={styles.checkList}>
                <li>
                  <a
                    href="https://www.omroepbrabant.nl/nieuws/2982494/Kapster-Annette-knipt-24-uur-voor-Stichting-Haarwens-Moeilijke-momenten-bij-zonsopkomst"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Omroep Brabant
                  </a>
                </li>
                <li>
                  <a
                    href="https://haarwensen.nl/acties/nieuws/833/knipmarathon-annette-hair-design-voor-haarwensen"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Stichting Haarwensen
                  </a>
                </li>
                <li>
                  <a
                    href="https://indebuurt.nl/helmond/bedrijvigheid/ondernemer-uitgelicht/kapster-annette-gaat-24-uur-lang-knippen-voor-het-goede-doel~62102/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    In de buurt
                  </a>
                </li>
                <li>
                  <a
                    href="https://nieuws.ditisonzewijk.nl/knipmarathon-annette-hair-design-goede-doel/amp/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Dit is onze wijk
                  </a>
                </li>
              </ul>

              <h3>Speciale dank aan</h3>

              <ul className={styles.checkList}>
                <li>Alle vrijwilligers</li>
                <li>Mijn collega kapster Linda</li>
                <li>
                  <a
                    href="http://safened.nl"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Safened Security
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.omroephelmond.nl"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Omroep Helmond
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.omroepbrabant.nl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Omroep Brabant
                  </a>
                </li>
                <li>
                  <a
                    href="https://ditisonzewijk.nl"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Dit is onze wijk
                  </a>
                </li>
                <li>
                  <a
                    href="https://indebuurt.nl/helmond"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    In de Buurt
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.deloop.eu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    De Loop weekkrant
                  </a>
                </li>
              </ul>

              <p>
                En heel erg bedankt lieve klanten, bewoners van Rivierenhof,
                vrienden en familie voor alle steun en donaties!! ❤❤❤
              </p>

              <a
                href="https://haarwensen.nl/acties/nieuws/833/knipmarathon-annette-hair-design-voor-haarwensen"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.haarwensenLink}
              >
                <SvgXLink color="#5aa974" {...require("svg/haarwensen.svg")} />
              </a>
            </Section>

            <ParallaxBannerImageText
              backgroundPosition="bottom center"
              image="wallpaper2"
              height={height}
              renderContent={($el, ratio) => (
                <BannerTitle
                  style={{ transform: `translateY(${ratio * 100 - 50}px)` }}
                >
                  <BannerTitleWordGroup
                    color={COLOR_TYPE_GREEN}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    De kapster
                  </BannerTitleWordGroup>
                  <BannerTitleWordGroup
                    color={COLOR_TYPE_WHITE}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    Voor hem & haar & kids
                  </BannerTitleWordGroup>
                </BannerTitle>
              )}
            />

            <Section theme={themes.WHITE_MODE}>
              <HeaderGroup
                id="prijzen"
                renderTitle={() => "Prijzen"}
                svg={require("svg/scissors.svg")}
              />
              <section className={styles.priceContainer}>
                <PriceCategory
                  svg={require("svg/product-category-heren.svg")}
                  price="€10,00"
                  text="Vanaf"
                  title="Heren"
                  className={styles.priceItem}
                />
                <PriceCategory
                  svg={require("svg/product-category-dames.svg")}
                  price="€17,50"
                  text="Vanaf"
                  title="Dames"
                  className={styles.priceItem}
                />
                <PriceCategory
                  svg={require("svg/product-category-kids.svg")}
                  price="€14,50"
                  text="Vanaf"
                  title="Kids"
                  className={styles.priceItem}
                />
                <PriceCategory
                  svg={require("svg/product-category-verven.svg")}
                  price="€29,50"
                  text="Vanaf"
                  title="Verven"
                  className={styles.priceItem}
                />
                <PriceCategory
                  svg={require("svg/product-category-permanent.svg")}
                  price="€57,50"
                  text="Vanaf"
                  title="Permanent"
                  className={styles.priceItem}
                />
                <PriceCategory
                  svg={require("svg/product-category-specials.svg")}
                  price="€4,50"
                  text="Vanaf"
                  title="Specials"
                  className={styles.priceItem}
                />
              </section>

              <Button
                type={ButtonTypes.GREEN}
                onClick={() => this.setPricesPageActive(true)}
              >
                Bekijk de hele prijslijst ⇢
              </Button>
            </Section>

            <ParallaxBannerImageText
              backgroundPosition="top right"
              image="wallpaper9"
              height={height}
              renderContent={($el, ratio) => (
                <BannerTitle
                  style={{ transform: `translateY(${ratio * 100 - 50}px)` }}
                >
                  <BannerTitleWordGroup
                    color={COLOR_TYPE_BROWN}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    Annette Hair-Design
                  </BannerTitleWordGroup>
                  <BannerTitleWordGroup
                    color={COLOR_TYPE_WHITE}
                    tweenTo={App.getBannerTitleAnimationType(ratio)}
                  >
                    Met en zonder afspraak
                  </BannerTitleWordGroup>
                </BannerTitle>
              )}
            />

            <Section
              theme={themes.BROWN_MODE}
              itemType="http://schema.org/HairSalon"
            >
              <HeaderGroup
                id="contact"
                renderTitle={() => "Contact"}
                svg={require("svg/contact.svg")}
              />

              <p>Gevestigd in woonzorgcentrum Rivierenhof.</p>

              <address className={styles.address}>
                Hof Bruheze 16, <br />
                5704 NP Helmond
              </address>

              <div className={styles.actionButtons}>
                <ButtonLink type={ButtonTypes.BROWN} href="tel:0630921075">
                  <SvgXLink {...require("svg/phone.svg")} />
                  <span>06-30921075</span>
                </ButtonLink>
                <ButtonLink
                  type={ButtonTypes.BROWN}
                  inverse={true}
                  href="mailto:info@annettehairdesign.nl?SUBJECT=Afspraak%20maken"
                >
                  <SvgXLink {...require("svg/mail.svg")} />
                  <span>info@annettehairdesign.nl</span>
                </ButtonLink>
              </div>
            </Section>

            <iframe
              title="Google Maps Location Annette Hair-Design"
              src={this.state.iframeSrc}
              width="100%"
              height="400"
              frameBorder="0"
              allowFullScreen
            />

            <Section theme={themes.BLUE_MODE}>
              <HeaderGroup title="Annette Hair-Design steunt" />
              <a
                href="https://haarwensen.nl/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.haarwensenLink}
              >
                <SvgXLink {...require("svg/haarwensen.svg")} />
              </a>
            </Section>

            <Section itemType="http://schema.org/HairSalon">
              <HeaderGroup
                renderTitle={() => "Openingstijden"}
                svg={require("svg/time.svg")}
              />
              <table className={styles.openingHoursTable}>
                <tbody>
                  <tr>
                    <td>Woensdag</td>
                    <td>
                      <time itemProp="openingHours" dateTime="We 13:00-18:00">
                        13:00 tot 18:00 uur
                      </time>
                    </td>
                  </tr>
                  <tr itemProp="openingHours">
                    <td>Vrijdag</td>
                    <td>
                      <time itemProp="openingHours" dateTime="Fr 13:00-21:00">
                        13:00 tot 21:00 uur
                      </time>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/*
              <InlineSVG
                element="div"
                style={{ width: 150, height: 150, display: "inline-block" }}
                src={require("svg/inline/sbb-beeldmerk-fc.svg")}
              />
              */ }
            </Section>
          </main>
        </Fragment>
      </ApplicationState.Provider>
    );
  }
}

export default process.env.NODE_ENV === "production"
  ? App
  : require("react-hot-loader").hot(module)(App);
