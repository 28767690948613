import React from "react";
import { shape, string, number } from "prop-types";
import classnames from "classnames";
import styles from "./price-category.scss";
import SvgXLink from "components/SvgXLink";

const PriceCategory = ({ title, text, svg, price, className, ...props }) => (
  <article className={classnames(styles.priceCategory, className)} {...props}>
    <div className={styles.icon}>
      <SvgXLink {...svg} />
    </div>
    <div className={styles.info}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.text}>
        {text}
        <span className={styles.price}>{price}</span>
      </div>
    </div>
  </article>
);

PriceCategory.propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  price: string.isRequired,
  svg: shape({
    viewBox: string,
    id: string
  }).isRequired
};

export default PriceCategory;
