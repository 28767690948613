import React from "react";
import { bool, node, string } from "prop-types";
import styles from "./section.scss";
import classnames from "classnames";
import DelimiterCornerBefore from "!!react-svg-loader!svg/delimiter-corner-before.svg?jsx=true";
import DelimiterCornerAfter from "!!react-svg-loader!svg/delimiter-corner-after.svg?jsx=true";

export const themes = {
  GREEN_MODE: styles.greenTheme,
  WHITE_MODE: styles.whiteTheme,
  BLUE_MODE: styles.blueTheme,
  BROWN_MODE: styles.brownTheme
};

const Section = ({ children, center, theme, className = "", ...props }) => (
  <section
    className={classnames([styles.host, theme, className], {
      [`${styles.center}`]: center
    })}
    {...props}
  >
    <DelimiterCornerBefore className={styles.delimiterBefore} />
    <div className={styles.container}>{children}</div>
    <DelimiterCornerAfter className={styles.delimiterAfter} />
  </section>
);

Section.propTypes = {
  center: bool,
  children: node,
  className: string,
  theme: string
};

Section.defaultProps = {
  center: false,
  theme: themes.WHITE_MODE
};

export default Section;
