import React, { PureComponent } from "react";
import { number, func, string, oneOfType } from "prop-types";
import styles from "./parallax-banner.scss";

/**
 * ParallaxBanner
 */
export default class ParallaxBanner extends PureComponent {
  $container = React.createRef();
  $background = React.createRef();
  $content = React.createRef();
  raf = null;
  state = { height: 0, y: 0, ratio: null };

  constructor(props) {
    super(props);
    this.onIntersect = this.onIntersect.bind(this);
    this.onTick = this.onTick.bind(this);
  }

  static propTypes = {
    /** The offset top and bottom to scroll */
    offsetY: oneOfType([string, number]),

    /** Render background prop */
    renderBackground: func.isRequired,

    /**  Render content prop */
    renderContent: func
  };

  static defaultProps = { offsetY: "20%" };

  onTick() {
    const { scrollY, innerHeight } = window;
    const offsetY = this.getOffsetY();
    const { y, height } = this.state;

    // calculate ratio
    const start = scrollY + innerHeight - y;
    const end = innerHeight + height;
    const ratio = Math.abs(start / end);

    // update ratio in state
    this.setState({ ratio });

    // update background position
    const distance = offsetY * 2;
    const backgroundY = -offsetY + distance * ratio;
    this.$background.current.style.transform = `translateY(${backgroundY}px)`;

    this.raf = requestAnimationFrame(this.onTick);
  }

  onIntersect(entries) {
    entries.forEach(({ isIntersecting }) => {
      if (isIntersecting) {
        this.raf = requestAnimationFrame(this.onTick);
      } else {
        this.raf = cancelAnimationFrame(this.raf);
      }
    });
  }

  getOffsetY() {
    let offsetY = this.props.offsetY;
    const isPercentage =
      typeof offsetY === "string" && offsetY.trim().slice(-1) === "%";

    if (this.state.height > 0 && isPercentage) {
      offsetY = this.state.height * (parseInt(offsetY) / 100);
    }

    return offsetY;
  }

  componentDidMount() {
    setTimeout(() => {
      const { y, height } = this.$container.current.getBoundingClientRect();

      // set height and offset
      this.setState({ height, y: window.scrollY + y });

      // set background height
      const offsetY = this.getOffsetY(this.props.offsetY);
      this.$background.current.style.height = `calc(100% + ${offsetY + 60}px)`;
      this.$background.current.style.marginTop = `-${offsetY / 2}px`;

      // set intersection observer
      if ("IntersectionObserver" in window) {
        const observer = new IntersectionObserver(this.onIntersect);
        observer.observe(this.$container.current);
      } else {
        this.raf = requestAnimationFrame(this.onTick);
      }
    }, 0);
  }

  render() {
    const { renderBackground, renderContent } = this.props;
    const { ratio } = this.state;
    const { $background, $content, $container } = this;

    return (
      <div className={styles.container} ref={$container}>
        <div className={styles.background} ref={$background}>
          {renderBackground($background.current, ratio)}
        </div>
        {renderContent && (
          <div className={styles.content} ref={$content}>
            {renderContent($content.current, ratio)}
          </div>
        )}
      </div>
    );
  }
}
