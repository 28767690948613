import React, { PureComponent } from "react";
import { bool } from "prop-types";
import styles from "./mobile-menu.scss";
import classnames from "classnames";
import links from "config/links";
import headerStyles from "components/Header/header.scss";
import appStyles from "containers/App/app.scss";

export default class MobileMenu extends PureComponent {
  static propTypes = {
    active: bool
  };

  static defaultProps = {
    active: false
  };

  container = React.createRef();
  headerHeight;
  htmlElement;
  mainElement;
  state = { mounted: false };

  /**
   * Set body block scroll state
   * @param {boolean} active
   */
  setBodyBlockScrollState = active => {
    if (!this.headerHeight || !this.htmlElement || !this.mainElement) {
      return false;
    }

    if (active) {
      this.htmlElement.classList.add("block-scroll");
      this.mainElement.style.paddingTop = `${this.headerHeight}px`;
    } else {
      this.htmlElement.classList.remove("block-scroll");
      this.mainElement.style.paddingTop = null;
    }
  };

  componentDidMount() {
    this.htmlElement = document.querySelector("html");
    this.mainElement = document.querySelector(`.${appStyles.main}`);

    setTimeout(() => {
      const header = document.querySelector(`.${headerStyles.host}`);
      this.headerHeight = header.getBoundingClientRect().height;
      this.setBodyBlockScrollState(this.props.active);
      this.setState({ mounted: true });
      this.container.current.style.height = `${window.innerHeight}px`;
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps.active !== this.props.active) {
      this.setBodyBlockScrollState(this.props.active);
    }
  }

  render() {
    const { active, onClick, ...props } = this.props;

    return (
      <nav
        role="navigation"
        ref={this.container}
        className={classnames(styles.mobileMenu, {
          [styles.active]: active,
          [styles.loaded]: this.state.mounted
        })}
        {...props}
      >
        <ul>
          {links.map(({ title, url, ...rest }, index) => (
            <li key={`mobile_menu_nav_${index}`}>
              <a
                href={url}
                onClick={e => {
                  if (/^#/.test(url)) {
                    onClick({ title, url, ...rest });
                  }
                }}
              >
                {title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}
