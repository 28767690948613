import React from "react";
import { func, node, shape, string } from "prop-types";
import { omit } from "ramda";

import SvgXLink from "components/SvgXLink";

import {
  compose,
  mapProps,
  setPropTypes,
  withProps,
  wrapDisplayName
} from "recompact";

import styles from "./header-group.scss";

/**
 * Header group for adding title and subtitle
 * with a divider of a line with an icon in the middle
 *
 * @example with renderProps:
 * <HeaderGroup
 *    renderTitle={() => "A title"}
 *    renderSubtitle={() => <p>A JSX.Element as subtitle</p>}
 *    svg={'svg/dryer.svg'}
 * />
 *
 * @example Instead of control the rendering of the title and subtitle
 * you can also provide just a string as prop
 * <HeaderGroup
 *    title="Some title"
 *    subtitle="Some subtitle"
 * />
 */
const HeaderGroup = ({ title, subtitle, svg, id }) => (
  <div className={styles.root}>
    <h2 id={id} className={styles.title}>
      {title}
    </h2>
    {svg && (
      <div className={styles.hr}>
        <SvgXLink {...svg} className={styles.icon} />
      </div>
    )}
    {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
  </div>
);

HeaderGroup.propTypes = {
  title: node,
  subtitle: node,
  svg: shape({
    viewBox: string,
    id: string
  }),
  className: string
};

export default compose(
  WrappedComponent => {
    const Comp = props => <WrappedComponent {...props} />;
    Comp.displayName = wrapDisplayName(HeaderGroup, "WithHeaderGroup");
    return Comp;
  },
  setPropTypes({
    renderTitle: ({ renderTitle, title }, propName, componentName) => {
      if (typeof renderTitle !== "function" && !title) {
        return new Error(
          `One of 'renderTitle' or 'title' is required by '${componentName}' component.`
        );
      }
    },
    renderSubtitle: func,
    title: string,
    subtitle: string,
    id: string
  }),
  withProps(({ title, subtitle, renderTitle, renderSubtitle, ...props }) => ({
    title: renderTitle ? renderTitle() : title,
    subtitle: renderSubtitle ? renderSubtitle() : subtitle,
    ...props
  })),
  mapProps(omit(["renderTitle", "renderSubtitle"]))
)(HeaderGroup);
