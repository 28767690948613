import React from "react";
import { bool, node, string, func } from "prop-types";
import classnames from "classnames";
import styles from "./button.scss";

export const ButtonTypes = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  GREEN: "green",
  BROWN: "brown"
};

export const ButtonLink = ({
  href,
  children,
  onClick,
  className,
  type,
  inverse,
  ...props
}) => (
  <a
    onClick={onClick}
    href={href}
    className={classnames(className, styles.buttonLink, {
      [styles.primary]: type === ButtonTypes.PRIMARY,
      [styles.secondary]: type === ButtonTypes.SECONDARY,
      [styles.brown]: type === ButtonTypes.BROWN,
      [styles.green]: type === ButtonTypes.GREEN,
      [styles.inverse]: inverse
    })}
    {...props}
  >
    {children}
  </a>
);

ButtonLink.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
  onClick: func,
  className: string,
  type: string,
  inverse: bool
};

ButtonLink.defaultProps = {
  onClick: () => {},
  className: "",
  type: ButtonTypes.PRIMARY,
  inverse: false
};

const Button = ({ children, onClick, className, type, inverse, ...props }) => (
  <button
    onClick={onClick}
    className={classnames(className, styles.button, {
      [styles.primary]: type === ButtonTypes.PRIMARY,
      [styles.secondary]: type === ButtonTypes.SECONDARY,
      [styles.brown]: type === ButtonTypes.BROWN,
      [styles.green]: type === ButtonTypes.GREEN,
      [styles.inverse]: inverse
    })}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: node.isRequired,
  onClick: func,
  className: string,
  type: string,
  inverse: bool
};

Button.defaultProps = {
  onClick: () => {},
  className: "",
  type: ButtonTypes.PRIMARY,
  inverse: false
};

export default Button;
