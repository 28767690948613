import React from "react";
import ReactDOM from "react-dom";
import App from "containers/App";

// @todo: enable SW code..

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js?v=1.0.3")
//       .then(registration => {
//         // eslint-disable-next-line no-console
//         console.log("SW registered: ", registration);
//       })
//       .catch(registrationError => {
//         // eslint-disable-next-line no-console
//         console.log("SW registration failed: ", registrationError);
//       });
//   });
// }

ReactDOM.render(<App />, document.getElementById("app"));
