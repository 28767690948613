import React from "react";
import ApplicationContext from "context/ApplicationContext";

/**
 * Higher order component wrap with application state
 * @param {React.ComponentType} WrappedComponent
 * @returns {React.SFC}
 */
const withApplicationState = WrappedComponent => props => (
  <ApplicationContext.Consumer>
    {state => <WrappedComponent {...props} {...state} />}
  </ApplicationContext.Consumer>
);

export default withApplicationState;
