import React, { Component } from "react";
import { string, oneOfType, number } from "prop-types";
import ParallaxBanner from "components/ParallaxBanner";
import styles from "./parallax-banner-image-text.scss";
import classnames from "classnames";

class ParallaxBannerImageText extends Component {
  static propTypes = {
    image: string.isRequired,
    backgroundPosition: string,
    height: oneOfType([string, number]),
    renderContent: ParallaxBanner.propTypes.renderContent,
    offsetY: ParallaxBanner.propTypes.offsetY
  };

  static defaultProps = {
    backgroundPosition: "center center",
    height: "auto",
    offsetY: "30%"
  };

  state = {
    imageEnhanced: null,
    imageThumb: require(`images/${this.props.image}-thumb.jpg`)
  };

  async componentDidMount() {
    const enhancedImage = await import(/* webpackMode: "lazy" */ `images/${
      this.props.image
    }.jpg`);
    const img = new Image();
    img.onload = () => this.setState({ imageEnhanced: enhancedImage.default });
    img.src = enhancedImage.default;
  }

  render() {
    const { backgroundPosition, renderContent, height, offsetY } = this.props;
    const { imageEnhanced, imageThumb } = this.state;

    return (
      <ParallaxBanner
        offsetY={offsetY}
        renderBackground={() => (
          <div
            className={classnames(styles.background, {
              [styles.loaded]: imageEnhanced
            })}
            style={{
              backgroundImage: `url(${imageEnhanced || imageThumb})`,
              backgroundPosition
            }}
          />
        )}
        renderContent={(...args) => (
          <div className={styles.content} style={{ height }}>
            {renderContent && renderContent(...args)}
          </div>
        )}
      />
    );
  }
}

export default ParallaxBannerImageText;
