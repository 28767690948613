import React from "react";
import { bool, string } from "prop-types";
import styles from "./hamburger-icon.scss";
import classnames from "classnames";

const HamburgerIcon = ({ active, className = "", ...props }) => (
  <button
    {...props}
    className={classnames(className, styles.hamburger, styles.collapse, {
      [styles.isActive]: active
    })}
    aria-label="Menu"
    aria-expanded={active.toString()}
  >
    <span className={styles.hamburgerBox}>
      <span className={styles.inner} />
    </span>
  </button>
);

HamburgerIcon.propTypes = {
  active: bool,
  className: string
};

HamburgerIcon.defaultProps = {
  active: false
};

export default HamburgerIcon;
