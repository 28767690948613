import React, { Children, cloneElement } from "react";
import { node } from "prop-types";
import styles from "./index.scss";

const BannerTitle = ({ children, ...props }) => {
  return (
    <div className={styles.bannerTitle} {...props}>
      {Children.map(children, (child, index) => {
        const delay = (Children.count(children) - index - 1) * 0.2;
        return cloneElement(child, { delay });
      })}
    </div>
  );
};

BannerTitle.propTypes = {
  children: node.isRequired
};

export default BannerTitle;
