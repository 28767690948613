import React, { useState, useEffect, useRef, useMemo } from "react";
import { instanceOf, node } from "prop-types";
import styles from "./countdown.scss";

const CountDown = ({ date }) => {
  const ref = useRef(null);
  const [obj, setObj] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null
  });

  const Animate = useMemo(
    () => {
      const F = ({ children }) => {
        const C = ref.current;

        return ref.current ? (
          <C
            className={styles.digit}
            durationOut={300}
            animationIn="bounceIn"
            animationOut="fadeOut"
          >
            {children}
          </C>
        ) : (
          <div className={styles.digit}>{children}</div>
        );
      };

      F.propTypes = {
        children: node
      };

      return F;
    },
    [ref.current]
  );

  useEffect(() => {
    const id = setInterval(() => {
      const now = new Date();

      import(/* webpackChunkName: 'react-animation', webpackMode: 'lazy'  */ "@nearform/react-animation").then(
        ({ AnimateOnChange }) => (ref.current = AnimateOnChange)
      );

      const diffInSeconds = Math.abs(date - now) / 1000;
      if (diffInSeconds <= 0) {
        clearInterval(id);
        return null;
      }

      setObj({
        days: Math.floor(diffInSeconds / 60 / 60 / 24),
        hours: Math.floor((diffInSeconds / 60 / 60) % 24),
        minutes: Math.floor((diffInSeconds / 60) % 60),
        seconds: Math.floor(diffInSeconds % 60)
      });
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <div className={styles.countdownContainer}>
      <div className={styles.left}>
        <div className={styles.days}>
          <Animate>{obj.days}</Animate>
          <div className={styles.timeIndicator}>days</div>
        </div>
        <div className={styles.hours}>
          <Animate>{obj.hours}</Animate>
          <div className={styles.timeIndicator}>hours</div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.minutes}>
          <Animate>{obj.minutes}</Animate>
          <div className={styles.timeIndicator}>minutes</div>
        </div>
        <div className={styles.seconds}>
          <Animate>{obj.seconds}</Animate>
          <div className={styles.timeIndicator}>seconds</div>
        </div>
      </div>
    </div>
  );
};

CountDown.propTypes = {
  date: instanceOf(Date)
};

export default CountDown;
