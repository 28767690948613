import React from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import classnames from "classnames";
import compose from "recompact/compose";
import SvgXLink from "components/SvgXLink";
import styles from "./header.scss";
import HamburgerIcon from "components/HamburgerIcon";
import withApplicationState from "hoc/withApplicationState";
import links from "config/links";

const Header = ({
  onClick,
  isMobileMenuActive,
  setMobileMenuActive,
  links,
  darkMode
}) => (
  <header
    className={classnames(styles.host, {
      [styles.darkMode]: darkMode
    })}
  >
    <div className={styles.logo}>
      <SvgXLink className={styles.svg} {...require("svg/colored-logo.svg")} />
    </div>

    <HamburgerIcon
      onClick={() => setMobileMenuActive(!isMobileMenuActive)}
      className={styles.hamburger}
      active={isMobileMenuActive}
    />

    <nav role="navigation" className={styles.navigation}>
      <ul>
        {links.map(({ title, url, ...rest }, index) => (
          <li key={`header_nav_${index}`}>
            <a
              href={url}
              onClick={e => {
                if (/^#/.test(url)) {
                  e.preventDefault();
                  onClick({ title, url, ...rest });
                }
              }}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  darkMode: bool,
  isMobileMenuActive: bool.isRequired,
  setMobileMenuActive: func,
  onClick: func,
  links: arrayOf(
    shape({
      title: string.isRequired,
      url: string.isRequired
    })
  )
};

Header.defaultProps = { links, darkMode: false };

export default compose(withApplicationState)(Header);
