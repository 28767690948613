import React, { PureComponent } from "react";
import {
  bool,
  string,
  number,
  node,
  func,
  object,
  oneOf,
  oneOfType
} from "prop-types";
import styles from "./index.scss";
import { TimelineMax, Power2 } from "gsap";
import classnames from "classnames";

export const COLOR_TYPE_GREEN = "green";
export const COLOR_TYPE_WHITE = "white";
export const COLOR_TYPE_BROWN = "brown";
export const COLOR_TYPE_YELLOW = "yellow";

/**
 * Banner title word group
 */
export default class BannerTitleWordGroup extends PureComponent {
  static propTypes = {
    color: string,
    delay: number,
    children: node,
    duration: number,
    play: bool,
    tweenTo: oneOf(["animate-in", "animate-in-end", "animate-out-end"]),
    ease: oneOfType([func, object])
  };

  static defaultProps = {
    duration: 1,
    delay: 0,
    ease: Power2.easeOut,
    play: false
  };

  timeline;
  element = React.createRef();
  elementContainer = React.createRef();

  onTweenUpdate(points) {
    this.element.current.style.webkitClipPath = `polygon(100% ${
      points.y1
    }%, 100% ${points.y2}%, 0 ${points.y3}%, 0 ${points.y4}%)`;
  }

  componentDidMount() {
    // extract prop properties
    const { duration, ease, delay } = this.props;

    // set init y points to animate
    // const yPoints = { y1: 0, y2: 100, y3: 100, y4: 80 };

    // create timeline
    this.timeline = new TimelineMax({
      paused: true,
      // onUpdate: this.onTweenUpdate.bind(this),
      // onUpdateParams: [yPoints]
    });

    // animate in
    this.timeline.add("animate-in");
    this.timeline.add("delayed", delay);
    this.timeline.fromTo(
      this.elementContainer.current,
      duration,
      { y: -80 },
      { y: 0, ease },
      "delayed"
    );
    this.timeline.fromTo(
      this.element.current,
      duration + 0.3,
      { opacity: 0 },
      { opacity: 1, ease },
      "delayed"
    );
    // this.timeline.fromTo(
    //   yPoints,
    //   duration,
    //   { y1: 0, y2: 100, y4: 100 },
    //   { y1: 0, y2: 100, y4: 0, ease },
    //   "delayed",
    //   0.2
    // );
    this.timeline.add("animate-in-end");

    // animate out
    this.timeline.add("animate-out");
    this.timeline.add("delayed", `+=${delay}`);
    // this.timeline.to(
    //   yPoints,
    //   duration,
    //   { y1: 0, y2: 0, y4: 0, ease: Power2.easeInOut },
    //   "delayed"
    // );
    this.timeline.to(
      this.elementContainer.current,
      duration,
      { y: 45, ease: Power2.easeInOut },
      "delayed"
    );
    this.timeline.to(
      this.element.current,
      duration,
      { opacity: 0, ease: Power2.easeInOut },
      "delayed"
    );
    this.timeline.add("animate-out-end");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tweenTo !== this.props.tweenTo) {
      this.timeline.tweenTo(this.props.tweenTo);
    }
  }

  render() {
    const { color, children } = this.props;

    return (
      <span
        ref={this.element}
        className={classnames(
          styles.wordGroup,
          `banner-title-word-group-color-${color}`
        )}
      >
        <span ref={this.elementContainer} className={styles.wordGroupContainer}>
          {children}
        </span>
      </span>
    );
  }
}
