import React from "react"
import { compose, mapProps } from "recompact"
import omit from "ramda/es/omit"
import { string } from "prop-types"

/**
 * -------------------------------------------
 * Wrapper output svg sprite xhref:link
 * @see https://github.com/kisenka/svg-sprite-loader
 *
 * @usage <SvgXLink {...require('./svg/sdfsdf.svg')} />
 * -------------------------------------------
 */

const SvgXLink = ({ viewBox, id, url, ...props }) => (
  <svg viewBox={viewBox} {...props}>
    <use xlinkHref={url} />
  </svg>
)

SvgXLink.propTypes = {
  viewBox: string.isRequired,
  url: string.isRequired
}

export default compose(mapProps(omit(["toString"])))(SvgXLink)
