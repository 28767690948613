import React from "react";
import { string, arrayOf, shape, bool, number } from "prop-types";
import classnames from "classnames";
import SvgXLink from "components/SvgXLink";
import styles from "./price-category-list.scss";

const PriceCategoryList = ({ title, svg, items, className, ...props }) => (
  <article
    className={classnames(styles.priceCategoryList, className)}
    {...props}
  >
    <header className={styles.header}>
      <SvgXLink className={styles.icon} {...svg} />
      <h2 className={styles.title}>{title}</h2>
    </header>
    <table className={styles.list}>
      <tbody>
        {items.map(({ text, price, fromPrice = false }, index) => (
          <tr key={index} className={styles.listRow}>
            <td className={styles.listRowText}>
              <span>{text}</span>
            </td>
            <td className={styles.listRowPrice}>
              {fromPrice && (
                <span className={styles.listRowFromPrice}>vanaf</span>
              )}
              € {price.toFixed(2).replace(".", ",")}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </article>
);

PriceCategoryList.propTypes = {
  title: string.isRequired,
  svg: shape(SvgXLink.PropTypes),
  items: arrayOf(
    shape({
      text: string.isRequired,
      price: number.isRequired,
      fromPrice: bool
    })
  )
};

export default PriceCategoryList;
