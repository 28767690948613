import React, { createContext } from "react";

export const defaultState = {
  isMobileMenuActive: false,
  isPricesPageActive: false,
  setMobileMenuActive: state => {},
  setPricesPageActive: state => {}
};

const ApplicationContext = createContext(defaultState);

export default ApplicationContext;
